<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.matter_contribution.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.matter_contribution.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.matter_contribution.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteMatterContribution"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "MatterContributionForm",
  mixins: [validationMixin],
  props: {
    MatterContribution: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          school: "",
          active: true,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matter_contribution: this.MatterContribution
        ? {
            id: this.MatterContribution.id,
            name: this.MatterContribution.name,
            description: this.MatterContribution.description,
          }
        : { id: generateUniqueId(), name: "" },
    };
  },
  validations: {
    matter_contribution: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.matter_contribution[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.matter_contribution.$touch();
      if (this.$v.matter_contribution.$anyError) {
        return;
      }
      if (isNaN(this.matter_contribution.id)) {
        this.createMatterContribution();
      } else this.updateMatterContribution();
    },
    createMatterContribution() {
      this.matter_contribution.school = this.institution_id;
      this.$store
        .dispatch(
          names.POST_MATTER_CONTRIBUTION,
          this.matter_contribution,
          this.matter_contribution.school
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.mattercontribution",
              false,
              "Estrategia Curricular"
            ) + " creada."
          );
          this.$emit("created", response);
        });
    },
    updateMatterContribution() {
      this.matter_contribution.school = this.institution_id;
      this.$store
        .dispatch(
          names.UPDATE_MATTER_CONTRIBUTION,
          this.matter_contribution,
          this.matter_contribution.school
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.mattercontribution",
              false,
              "Estrategia Curricular"
            ) + " actualizada."
          );
          this.$emit("updated", response);
        });
    },
    deleteMatterContribution() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
          "mesh.mattercontribution",
          false,
          "Estrategia Curricular"
        )}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_MATTER_CONTRIBUTION,
            this.matter_contribution.id
          );
          this.$emit("deleted", this.matter_contribution);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>